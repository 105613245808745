import React from 'react';
import { Box, Fade, Spacer, Text, Image } from '@chakra-ui/react';

import arthi from './static/arthi.jpg';

function Home() {
  const [showText, setShowText] = React.useState(false);
  return (
    <React.Fragment>
      <Spacer />
      <Box p={1} display={{ lg: 'flex' }}>
        <Box>
          <Fade in={true} delay={0.25}>

            <Image
              boxSize="100%"
              src={arthi}
              marginLeft="10px"
              fit="contain"
              onLoad={() => {
                setShowText(true);
              }}
            />
          </Fade>
        </Box>
        {showText && (
          <Fade in={true} delay={0.25}>
            <Box
              backgroundColor="#c66a46"
              borderRadius="lg"
              mr={{ base: '25px', lg: '100px' }}
              mb={{ base: '25px', lg: '100px' }}
              mt="0px"
              ml={{ base: '25px', lg: '0px' }}
              height="fit-content"
            >
              <Text
                fontSize={{ base: 'xl', lg: '3xl' }}
                fontWeight="bold"
                padding="20px"
                paddingBottom="0px"
                color="#ecebe6"
              >
                Hi! I'm Arthi!
              </Text>
              <Text
                fontSize='md'
                padding="20px"
                color="#ecebe6"
              >
                I’m a software engineer with expertise in data science and machine learning systems. Over the past 7 years, I’ve contributed to several search and recommendation products at Facebook by using quantitative methods to identify system gaps, designing objectives to capture value, and building large-scale retrieval systems and ranking models. I currently work on friending.
                <br />
                <br />
                Throughout all my roles — data scientist, engineer, musician, student, leader, people manager — one thing I’ve consistently enjoyed is driving efficiency through “better systems.” I hope to spend the next phase of my career building tech that helps people be more productive, self-aware, and healthy. More on this soon!
              </Text>
            </Box>
          </Fade>
        )}
      </Box>
      {/* <Flex>
        <Box
          backgroundColor="#fffdf2"
          width="100%"
          mx={{ base: '25px', lg: '100px' }}
          mb="50px"
          opacity="70%"
          borderRadius="lg"
        >
          <Accordion allowMultiple>
            <HomePageDropDown
              heading="Who am I?"
              content="I identify as a musician, data scientist, engineer, leader, INFJ, woman, student, gardener, vegetarian, 
              film buff, Tamilian, and national park enthusiast. I’m happiest in the company of artists."
            />

            <HomePageDropDown
              heading="What am I interested in?"
              content="I spend a lot of my time thinking about how technology can be used to foster mental fitness, health and productivity."
            />

            <HomePageDropDown
              heading="What is some of the work I've done?"
              content="My work has involved using data to build more valuable products
              - whether it's designing metrics that best represent user value, developing methods to do 
              failure analysis on technical systems, causal inference and experimentation methods to 
              understand complex ecosystems, or building signals and models for recommendation systems.
              I'm currently working on a building a few applications as side projects - more on this soon.
              "
            />
          </Accordion>
        </Box>
      </Flex> */}
    </React.Fragment >
  );
}

export default Home;
