import React from 'react';

import { Link } from "react-router-dom"
import { Heading, LinkBox, Image, Container, WrapItem, Text } from '@chakra-ui/react';

function BlogPostBox({ id, image, heading, preview }) {

  return (
    <WrapItem>
      <Container margin="auto" width={{ base: "100%", lg: "xs" }} align="center">
        <Link to={`/blog/${id}`}>
          <LinkBox margin="10px" width={{ base: "80%", lg: "100%" }} minHeight={{ base: "200px", lg: "400px" }} borderWidth="1px" borderRadius="lg" bgColor="white" boxShadow="md" paddingBottom="5px" >
            <Image src={image} maxHeight="50%" borderTopRadius="lg" align="center" width={{ lg: "xs", base: "100%" }} fit="cover" />
            <Heading size="md" margin="3">
              {heading}
            </Heading>
            <Text margin="3">
              {preview}
            </Text>
          </LinkBox >
        </Link>
      </Container>
    </WrapItem>
  );
}

export default BlogPostBox;
