import {
  Heading,
  Flex,
  Spacer,
  Image,
  useBreakpointValue,
  Box,
  Fade
} from '@chakra-ui/react';

import React from 'react';
import logo from './static/logo.png';

import CollapsibleMenu from './CollapsibleMenu';

function Header() {
  const headingType = useBreakpointValue({ sm: 'h4', lg: 'h1' });
  return (
    <Fade in={true} delay={0.25}>
      <Flex>
        <Image htmlHeight="90px" htmlWidth="90px" src={logo} margin="20px" fallback={<Box width="90px" height="90px" margin="20px" backgroundColor="#ecebe6" />} />
        <Heading as={headingType} color="#3f3c34" paddingTop="43px">
          Arthi Suresh
        </Heading>

        <Spacer />

        <CollapsibleMenu />
      </Flex>
    </Fade>
  );
}

export default Header;
