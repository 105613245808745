import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  useBreakpointValue,
  Breadcrumb,
  BreadcrumbItem,
  Text,
} from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useLocation } from 'react-router-dom';

function CollapsibleMenu() {
  const isLarge = useBreakpointValue({ md: true, sm: false });
  const location = useLocation();
  if (isLarge === true) {
    return (
      <Breadcrumb separator="|" mr={{ base: '25px', lg: '100px' }} pt="54px">
        <BreadcrumbItem as={Link} to="/">
          <Text
            textDecoration={location.pathname === '/' && 'underline'}
            textUnderlineOffset={5}
          >
            Home
          </Text>
        </BreadcrumbItem>

        <BreadcrumbItem as={Link} to="/blog/:id">
          <Text
            textDecoration={location.pathname.startsWith('/blog') && 'underline'}
            textUnderlineOffset={5}
          >
            Blog
          </Text>
        </BreadcrumbItem>

        {/* <BreadcrumbItem as={Link} to="/projects">
          <Text
            textDecoration={location.pathname === '/projects' && 'underline'}
            textUnderlineOffset={5}
          >
            Projects
          </Text>
        </BreadcrumbItem> */}

        <BreadcrumbItem as={Link} to="/contact">
          <Text
            textDecoration={location.pathname === '/contact' && 'underline'}
            textUnderlineOffset={5}
          >
            Contact
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  } else {
    return (
      <Box pr="20px" pt="40px">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />}
            variant="outline"
            _focus={{ boxShadow: 'none' }}
          />
          <MenuList>
            <MenuItem as={Link} to="/" backgroundColor={location.pathname === '/home' && "#e3e8ef"}>
              Home
            </MenuItem>

            <MenuItem as={Link} to="/blog" backgroundColor={location.pathname.startsWith('/blog') && "#e3e8ef"}>
              Blog
            </MenuItem>

            {/* <MenuItem as={Link} to="/projects">
              Projects
            </MenuItem> */}

            <MenuItem as={Link} to="/contact" backgroundColor={location.pathname === '/contact' && "#e3e8ef"}>
              Contact
            </MenuItem>
          </MenuList>
        </Menu>
      </Box >
    );
  }
}

export default CollapsibleMenu;
