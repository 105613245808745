import React from 'react';
import {
    Box,
    Heading,
    Divider,
    Flex
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import {
    useParams,
    Redirect,
    Link
} from "react-router-dom";
import {
    ArrowBackIcon
} from "@chakra-ui/icons";
import { POSTS } from './constants.js';

const getHeadingComponent = (level) => {
    let size;
    if (level === "h1") {
        size = "lg"
    }
    else if (level === "h2") {
        size = "md"
    }
    else {
        size = "sm"
    }
    const Component = (props) => { return <Heading as={level} size={size} {...props} /> }
    return Component
}

const COMPONENT_MAPPING = {
    "h1": getHeadingComponent("h1"),
    "h2": getHeadingComponent("h2"),
    "h3": getHeadingComponent("h3"),
    "p": (props) => {
        return <p style={{ marginTop: 15, marginBottom: 15 }} {...props} />
    }
}

function ExpandedBlogPost() {
    let { id } = useParams();

    if (POSTS[id] === undefined) {
        return <Redirect to="/blog" />
    }
    return (
        <Flex margin={{ base: "20px", sm: "10x", xs: "10x" }}>
            <Box padding="20px" backgroundColor="white" borderRadius="lg" boxShadow="md" flex='1' mx="20px" alignItems="center" justifyContent="center">
                <Flex>
                    <Link to="/blog" replace={true} style={{ display: "flex" }}><ArrowBackIcon alignSelf="center" boxSize={{ base: "30px", md: "35px", lg: "40px" }} color="#a0c7b4" /></Link>
                    <Heading color="#c66a46" my="5px" size="xl" ml="10px">
                        {POSTS[id].heading}
                    </Heading>
                </Flex>
                <Divider my="20px" />
                <Box>
                    <Box>
                        <ReactMarkdown components={COMPONENT_MAPPING}>{POSTS[id].content}</ReactMarkdown>
                    </Box>
                </Box>
            </Box >
        </Flex >
    );
}

export default ExpandedBlogPost;
