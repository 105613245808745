
import raw from "raw.macro/dist/raw.macro";
import intro from "./static/intro.png";
import infra from "./static/infra.png";
import books from "./static/books.png";

export const POSTS = {
    "intro": { "heading": "Hello world!", "content": raw("./static/posts/intro.md"), "preview": "introducing this blog", "image": intro },
    "infra": { "heading": "The infra behind this website", "content": raw("./static/posts/infra.md"), "preview": "React, AWS, Terraform", "image": infra },
    "books": { "heading": "Best reads of 2023", "content": raw("./static/posts/books.md"), "preview": "a collection of my favorite books from 2023", "image": books }

}

export const COLORS = {
    "orange": "#c66a46"
}
