import React from 'react';
import { Box, Fade, Image, IconButton } from '@chakra-ui/react';
import { IoLogoLinkedin, IoMdMail } from 'react-icons/io';

import pic from './static/italy.jpg';

function Contact() {
  return (
    <Fade in={true} delay={0.25}>
      <Box align="center">
        <Box>
          <Image
            borderRadius="full"
            boxSize="xs"
            src={pic}
            alt="Arthi Suresh"
            mb="20px"
            loading="eager"
            fit="cover"
          />
        </Box>

        <Box>
          <IconButton
            variant="link"
            icon={<IoMdMail size="50px" />}
            color="#c66a46"
            mx="10px"
            onClick={() =>
              window.open('mailto:sendittoarthi@gmail.com', '_blank')
            }
          />
          <IconButton
            variant="link"
            icon={<IoLogoLinkedin size="50px" />}
            color="#c66a46"
            onClick={() =>
              window.open(
                'https://www.linkedin.com/in/arthi-suresh-a3600683/',
                '_blank'
              )
            }
          />
        </Box>
      </Box>
    </Fade>
  );
}

export default Contact;
