import React from 'react';
import { Fade, Wrap } from '@chakra-ui/react';

import BlogPostBox from './BlogPostBox';
import { POSTS } from './constants';
function Blog() {

  return (
    <Fade in={true} delay={0.25}>
      <Wrap px="8%" justify="center">
        {Object.entries(POSTS).map(([postId, post]) => {
          return <BlogPostBox key={postId} id={postId} image={post.image} heading={post.heading} preview={post.preview} />
        })}
      </Wrap>
    </Fade>
  );
}

export default Blog;
